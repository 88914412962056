import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query, SanityBlogConnection } from "@graphql-types";
import SEO from "@shared/seo";
import Hero from "@shared/hero";
import { useRegionChanged } from "@util/hooks";
import { BlogFeaturedNews, BlogOtherNews } from "@global";

interface Data extends Query {
  altRegions: SanityBlogConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: { iban: string };
}

export default function BlogLandingPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityBlog;
  const { nodes: blogNodes } = data.allSanityBlogs;
  const { seo, blogHero, featuredBlock } = nodes[0];
  const alternateSlugs = data.altRegions.nodes.map((alt) => alt.region);
  useRegionChanged("blog", pageContext.iban);

  return (
    <div>
      <SEO seoData={seo} slug="blog" alternateRegions={alternateSlugs} heroData={blogHero}/>
      <Hero heroData={blogHero} />
      {featuredBlock && <BlogFeaturedNews data={featuredBlock} />}
      <BlogOtherNews data={blogNodes} />
    </div>
  );
}

export const query = graphql`
  query BlogLandingPageQuery($iban: String) {
    allSanityBlog(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        blogHero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        featuredBlock {
          ...sanityBlogs
        }
      }
    }
    allSanityBlogs(sort: { order: DESC, fields: publishDate }) {
      nodes {
        ...sanityBlogs
      }
    }
    altRegions: allSanityBlog {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
